<template>
  <div class="row site-quick-actions">
    <h4 class="text-dark">{{ $t(headerLabel) }}</h4>

    <div class="col-12">
      <div class="resource-block mb-3">
        <DownloadButton :options="serverDownloadOptions">
          <a class="mb-1"
            ><b>1.</b> {{ $t('Download FileCloud Server') }}</a
          >
        </DownloadButton>

        <p class="mb-0">
          {{ $t('Run FileCloud Server on a wide variety of platforms') }}
        </p>
      </div>
    </div>

    <div v-if="licenseURL" class="col-12">
      <div class="resource-block mb-3">
        <a :href="licenseURL" target="_blank" class="mb-1"
          ><b>2.</b> {{ $t('Download your license') }}</a
        >
        <p class="mb-0">
          {{
            $t(
              'You will need your license to install activate your FileCloud installation'
            )
          }}
        </p>
      </div>
    </div>

    <div class="col-12">
      <div class="resource-block mb-3">
        <a
          href="https://www.getfilecloud.com/supportdocs/display/cloud/Getting+Started+Tutorials"
          target="_blank"
          class="mb-1"
          ><b>{{ licenseURL ? 3 : 2 }}</b>
          {{ $t('Learn the FileCloud basics') }}</a
        >
        <p class="mb-0">{{ $t('Watch videos on how to get started.') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadSections } from '@/constants/downloadsPage';
import DownloadButton from '@/components/Downloads/DownloadButton';

export default {
  components: {
    DownloadButton,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },

  computed: {
    downloadLinks() {
      return this.$store.state.core.downloadLinks;
    },
    headerLabel() {
      return this.site.istrial
        ? `Getting Started with your ${
            this.site.iscommunity ? 'Community' : 'Server'
          } Trial`
        : `Getting Started with FileCloud ${
            this.site.iscommunity ? 'Community' : 'Server'
          }`;
    },
    licenseURL() {
      if (!this.site || this.site.licensegroup === 'Online') return '';
      if (this.site.licensegroup === 'Community')
        return '/core/?op=gettrialcommunitylicense';
      if (this.site.istrial) return '/core/?op=gettriallicense';
      if (this.site.id && typeof this.site.id === 'string')
        return `/core/?op=getlicense&saleid=${this.site.id}`;
      return '';
    },
    serverDownloadOptions() {
      return downloadSections.find((section) => section.id === 'server')
        .downloadOptions;
    },
  },
};
</script>

<style></style>
